import { Center, Spinner } from "@chakra-ui/react";

const Loading = () => {
    return (
        <Center my={ 20 }>
            <Spinner />
        </Center>
    );
};

export default Loading;
