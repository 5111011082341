import {
    Box,
    Flex,
    HStack,
    IconButton,
    useDisclosure,
    useColorModeValue,
    Stack,
    Image,
    Heading,
    Tag,
    TagLabel,
    Badge,
} from "@chakra-ui/react";

import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import NavLink from "./nav-link";
import Footer from "./footer";
import WalletData from "./wallet-data";
import Logo from './images/logo.png';
import useOracle from "../../hooks/useOracle";
import { useOracleData } from "../../hooks/useOracleData";
import { useEffect, useState } from "react";

const Links = [{
    name: "Home",
    to: "/",
}, {
    name: "Marketplace",
    to: "/marketplace",
}];

const MainLayout = ({ children }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const priceOracle = useOracleData();
    const [price, setPrice] = useState("0");

    useEffect(() => {
        const pricePromise = priceOracle.then(result => {
            if (typeof(result.priceOracle) == "string") {
                setPrice(result.priceOracle)
            }
        })
    }, [price, priceOracle])

    return (
        <Flex minH="100vh" direction="column">
            <Box
                mx="auto"
                maxW={ "7xl" }
                width="100%"
                bg={ useColorModeValue("white", "gray.800") }
                px={ 4 }
            >
                <Flex
                    bg={ useColorModeValue("white", "gray.800") }
                    color={ useColorModeValue("gray.600", "white") }
                    minH={ "60px" }
                    py={{ base: 2 }}
                    px={{ base: 4 }}
                    borderBottom={ 1 }
                    borderStyle={ "solid" }
                    borderColor={ useColorModeValue("gray.200", "gray.900") }
                    alignItems={ "center" }
                    justifyContent={ "space-between" }
                >
                    <IconButton
                        size={ "md" }
                        icon={ isOpen ? <CloseIcon /> : <HamburgerIcon /> }
                        aria-label={ "Open Menu" }
                        display={{ md: "none" }}
                        onClick={ isOpen ? onClose : onOpen }
                    />
                    <HStack spacing={ 8 } alignItems={ "center" }>
                        <Flex alignItems="center">
                            <Image src={ Logo } width='130px' />
                        </Flex>
                        <HStack
                            as={ "nav" }
                            spacing={ 4 }
                            display={{ base: "none", md: "flex" }}
                        >
                            {
                                Links.map(({ name, to }) => (
                                    <NavLink key={ name } to={ to }>
                                        { name }
                                    </NavLink>
                                ))
                            }
                        </HStack>
                    </HStack>
                    <HStack>
                        <Tag colorScheme="green" borderRadius="full">
                                <TagLabel>
                                    ${ parseInt(price/100000000).toLocaleString() }
                                </TagLabel>
                                <Badge
                                    d={{
                                        base: "none",
                                        md: "block",
                                    }}
                                    variant="solid"
                                    fontSize="0.8rem"
                                    ml={ 1 }
                                >
                                    ETH/USD
                                </Badge>
                            </Tag>
                        <WalletData />
                    </HStack>
                </Flex>

                {
                    isOpen ? (
                        <Box pb={ 4 } display={{ md: "none" }}>
                            <Stack as={ "nav" } spacing={ 4 }>
                                {
                                    Links.map(({ name, to }) => (
                                        <NavLink key={ name } to={ to }>
                                            { name }
                                        </NavLink>
                                    ))
                                }
                            </Stack>
                        </Box>
                    ) : null
                }

            </Box>
            <Box mx="auto" flex={ 1 } p={ 4 } maxW={ "7xl" } width="100%">
                { children }
            </Box>
            <Footer />
        </Flex>
    );
};

export default MainLayout;
